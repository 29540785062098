<template>
  <div class="signup-done" v-if="!isProcessing">
    <div class="signup-done__complete">
      <v-icon class="signup-done__complete__icon">check_circle_outline</v-icon>
      <h2 class="signup-done__complete__title">LDWへの登録が完了しました</h2>
      <p class="signup-done__complete__text">
        Facebookグループへの参加申請をお願いします。<br>
        <a class="signup-done__complete__text__url" target="_blank"
           href="https://www.facebook.com/groups/656661221552950">
          https://www.facebook.com/groups/656661221552950
        </a><br><br>
        参加申請の承認まで3営業日ほどいただく場合がございます。あらかじめご了承ください。
      </p>
      <v-btn class="signup-done__complete__btn" depressed @click="$router.push({ name: 'home' })">ホームへ移動する</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'signup-done',
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.signup-done {
  display: flex;
  align-items: center;
  width: 100vw;
  max-width: $max_width;
  min-height: 100vh;
  margin: 0 auto;
  text-align: center;
  background-color: $green_color;
  &__complete {
    width: 100%;
    padding: $padding_height $padding_width;
    &__icon {
      font-size: 20rem;
      color: $white_color;

      @media (max-width: $max_width) {
        font-size: calc(100vw * 0.488);
      }
    }
    &__title {
      margin-top: 10px;
      font-size: 2rem;
      font-weight: bold;
      color: $white_color;
    }
    &__text {
      margin: 30px 0 0;
      font-size: 1.4rem;
      font-weight: normal;
      color: $white_color;
      text-align: justify;
      word-wrap: break-word;
      &__url {
        color: $white_color !important;
        text-decoration: underline;
      }
    }
    &__btn {
      display: block;
      margin: 40px auto 0;
      font-size: 1.2rem;
      color: $white_color;
      border-radius: 15px;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: 136px;
        height: 36px;
        padding: 0 20px;
      }
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $orange_color;
      }
    }
  }
}
</style>
